@import url("https://cdn.jsdelivr.net/gh/jgthms/minireset.css@master/minireset.min.css");

html {
  scroll-behavior: smooth;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Arial", "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f7fa;
}

@font-face {
  font-family: "ShellBold";
  src: local("ShellBold"), url(../assets/fonts/ShellBold.otf) format("opentype");
}

@font-face {
  font-family: "ShellHeavy";
  src: local("ShellHeavy"),
    url(../assets/fonts/ShellHeavy.otf) format("opentype");
}
